import React, { Component } from "react";
import { Route } from "react-router-dom";

class PublicRoute extends Component {
  render() {
    const { component, path, rest } = this.props;

    return (
      <Route
        path={path}
        render={(props) => React.createElement(component, props)}
        {...rest}
      />
    );
  }
}

export default PublicRoute;
