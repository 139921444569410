import HomePage from "Views/HomePage/HomePage";
import QrPage from "Views/QRPage/QRPage";
import StablaPage from "Views/StablaPage/StablaPage";

const publicRoutes = [
  { path: "/nasice/qr/:id", name: "QR", component: QrPage},
  { path: "/valpovo/qr/:id", name: "QR", component: QrPage},
  { path: "/nasice/stabla", name: "Stabla", component: StablaPage},
  { path: "/valpovo/stabla", name: "Stabla", component: StablaPage},
  { path: "/", name: "Home", component: HomePage }
];

export default publicRoutes
