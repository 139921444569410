import Fuse from 'fuse.js'

class fuzzySearch {
	constructor(keys, records){

		this.fuseOptions = {
		  threshold: 0.3,
		  location: 0,
		  distance: 1000,
		  maxPatternLength: 16,
		  minMatchChatLength: 2,
		  keys: keys
		}
		
		this.fuzzy = new Fuse(records, this.fuseOptions);
		this.search = this.search.bind(this);
	}

	search(query) {
		return this.fuzzy.search(query ? query : '')
	}
}

export default fuzzySearch;