import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';


import './styles/layout.css';
import './styles/index.css';
import './styles/react-datetime.css';

import "./i18n";

ReactDOM.render(<App/>, document.getElementById("root"));
