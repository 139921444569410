import moment from "moment";
import numbro from "numbro";

import api from "Lib/api";

class dataController {
  constructor(model) {
    this.apiPath = model.apiPath;
    this.listViews = model.listViews;
    this.forms = model.forms;
    this.fields = model.fields;
    this.source = model.source

    this.records = [];

    this.fieldId = this.fields.find(attr => {
      return attr.idattr === true;
    });

    this.api = new api();
  }

  CustomPutAction(url, data) {
    const putData = this.prepareUpdateData(data);
    return this.api.Call(url, "put", putData);
  }

  CustomPostAction(url, data, multi = false) {
    let postData = null;

    if (multi) {
      postData = data.map(d => this.prepareInsertData(d))
    } else {
      postData = this.prepareInsertData(data);
    }


    return this.api.Call(url, "post", postData);
  }

  GetData(customPath = null) {
    const path = customPath !== null ? customPath : this.apiPath;
    return this.api.Call(path, "get")
      .then(resp => {
        if (Array.isArray(resp.data)) {
          const data = resp.data.map(record => this.prepareReturnData(record))
          const success = resp.success;
          return {success: success, data: data};
        } else {
          return {success: resp.success, data: this.prepareReturnData(resp.data)}
        }
      })
  }

  GetDataSingle(id) {
    const path = this.apiPath + "/" + id;

    return this.api.Call(path, "get")
      .then(resp => {
        if (resp.success) {
          const returnData = this.prepareReturnData(resp.data);
          return { success: true, data: returnData };
        } else {
          return { sucess: false, error: resp.error };
        }
      });
  }

  getRecordObject(recordId) {
    if (this.fieldId && (recordId !== null || recordId !== undefined)) {
      let res = this.records.find(r => {
        return r[this.fieldId.source] == recordId;
      });
      if (res) {
        return Object.assign({}, res); //clone object
      } else {
        return null;
      }
    }
    return null;
  }

  // getRecordObjects(filters) {
  //   let recordsCopy = JSON.parse(JSON.stringify(this.records)); //clone
  //   if (filters) {
  //     filters.forEach(f => {
  //       recordsCopy = recordsCopy.filter(rec => {
  //         if ( Array.isArray(f.value) ){
  //           return f.value.indexOf( rec[f.name] ) !== -1
  //         } else {
  //           return rec[f.name] === f.value
  //         }
  //       })
  //     })
  //   }

  //   return recordsCopy;
  // }

  InsertRecord(record) {
    let insertData = this.prepareInsertData(record);

    return this.api
      .Call(this.apiPath, "post", insertData)
      .then(response => {
        if (response && response.success) {
          let newId = response.data.id;
          if (newId) {
            return Promise.resolve({ success: true, data: response.data, id: newId });
          } else {
            return Promise.resolve({ success: true, data: response.data });
          }
        } else {
          return Promise.reject({ success: false, error: response.error });
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  UpdateRecord(recordId, record) {
    if (!this.fieldId) {
      this.debugLog(
        "Attempt to update record without id attribute in the model!"
      );
      return Promise.reject("Unknown ID field!");
    }

    if (!recordId) {
      this.debugLog("Attempt to update record withhout recordId!");
      return Promise.reject("Unknown record Id!");
    }

    let updateData = this.prepareUpdateData(record);

    return this.api
      .Call(this.apiPath, "put", updateData)
      .then(response => {
        if (response.success) {
          return Promise.resolve({ success: true, data: response.data });
          // switch (data.result) {
          //   case 'updated':
          //   case 'fail':
          //     return Promise.reject();
          //   default:
          //     return Promise.reject();
          // }
        } else {
          return Promise.reject( { success: false, error: response.error });
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  DeleteRecord(recordId) {
    if (!this.fieldId) {
      this.debugLog(
        "Attempt to delete record without id attribute in the model!"
      );
      return Promise.reject("Unknown ID field!");
    }

    if (!recordId) {
      this.debugLog("Attempt to delete record withhout recordId!");
      return Promise.reject("Unknown record Id!");
    }

    let deleteData = {};
    deleteData[this.fieldId.source] = recordId;

    return this.api
      .Call(this.apiPath, "delete", deleteData)
      .then(response => {
        if (response.success) {
          return Promise.resolve({success: true, data: response.data });
        } else {
          return Promise.resolve({ success: false, error: response.error });
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  debugLog(msg) {
    console.warn(msg);
  }

  prepareReturnData(record) {
    let data = Object.assign({}, record);

    //we pass everything (filter keys also!)
    Object.keys(data).forEach(key => {
      const field = this.getField(key);
      if (field) {
        switch (field.type) {
          case "date":
          case "datetime":
              if (data[key]) {
                data[key] = moment.utc(data[key]);
              }
              break;
          case "radio":
            if (field.changeValues) {
              if (data[key] === null) {
                data[key] = -1;
              } else if (data[key] === false) {
                data[key] = 0;
              } else if (data[key] === true) {
                data[key] = 1;
              }
            }
            break;
          case "dokumenti":
            if (!Array.isArray(data[key])) {
              data[key] = [data[key]]
            }
          case "table":
            let arr = data[key];
            let parseArr = [];
            arr.forEach(rec => {
              parseArr.push(this.prepareReturnData(rec));
            })
            data[key] = parseArr;
            break;
          default:
        }

      }
    });

    return data;
  }

  prepareInsertData(record) {
    let data = Object.assign({}, record);

    //we pass everything (filter keys also!)
    Object.keys(data).forEach(key => {
      const field = this.getField(key);
      if (field) {
        switch (field.type) {
          case "date":
          case "datetime":
            if (this.config) {
              data[key] = moment.utc(data[key]).toISOString(true);
            }
            break;
          case "richtext":
            if (data[key]) {
              data[key] = data[key].replace('&nbsp;', ' ');
            }
            break;
          case "picker":
          case "pickergroup":
          case "radio":
            if (typeof data[key] === "object" && data[key] !== null && data[key].hasOwnProperty('value')) {
              if (field.changeValues) {
                if (data[key].value === -1 ){
                  data[key] = null;
                } else if (data[key].value === 0) {
                  data[key] = false;
                } else if (data[key].value === 1) {
                  data[key] = true;
                }
              } else {
                if (data[key].value === -1){
                  data[key] = null;
                } else {
                  data[key] = data[key].value;
                }
              }
            } else if (field.changeValues && typeof data[key] === "number") {
              if (data[key] === -1 ){
                data[key] = null;
              } else if (data[key] === 0) {
                data[key] = false;
              } else if (data[key] === 1) {
                data[key] = true;
              }
            } else if (field.multi) {
              if (Array.isArray(data[key])) {
                data[key] = data[key].map(d => d.value ? d.value : d)
              }
            }
            break;
          case "checkbox":
            if (Array.isArray(data[key]) && data[key].length > 0) {
              const dataArray = [];
              let dataInt = 0;
              if (field.flags) {
                data[key].forEach(d => {
                  dataInt += d.value;
                });
                data[key] = dataInt;
              } else {
                data[key].forEach(d => {
                  if (d.hasOwnProperty('value')) {
                    dataArray.push(d.value);
                  } else {
                    dataArray.push(d);
                  }
                });
                data[key] = dataArray;
              }
            }
            break;
          case "checkbox_custom":
            if (Array.isArray(data[key])) {
              data[key] = data[key].filter(d => d.favorite).map(d => (d.uuid || d.id));
            }
            break;
          case "numeric":
            if (data[key] !== null && typeof data[key] === "string") {
              data[key] = parseFloat((data[key]).replace(',','.'))
            }
            break;
          case "currency":
            if (data[key] !== null) {
              const parsedValue = numbro(data[key]);
              numbro.setLanguage("en-US");
              data[key] = numbro.unformat(parsedValue.format());
              numbro.setLanguage("hr-HR");
            }
            break;
          // case "images":
          case "dokumenti":
            if (field.object) {
              data[key] = data[key][0];
            }
            break;
          case "table":
            let arr = data[key];
            let parseArr = [];
            arr.forEach(rec => {
              parseArr.push(this.prepareInsertData(rec));
            })
            data[key] = parseArr;
            break;
        }
      }
    });
    // password change doesnt need id_attr
    if (this.fieldId) {
      data[this.fieldId.source] = record[this.fieldId.source]; //ensure we have recordId
    }

    return data;
  }

  prepareUpdateData(record) {
    let data = Object.assign({}, record);

    Object.keys(data).forEach(key => {
      const field = this.getField(key);
      if (field) {
        // if (field.bind) {
        switch (field.type) {
          case "date":
          case "datetime":
            if (this.config) {
              data[key] = moment.utc(data[key]).toISOString(true);
            }
            break;
          case "richtext":
            const reg = /\&nbsp\;/gi;
            const regAmp = /\&amp\;/gi;
            if (data[key]) {
              data[key] = data[key].replace(reg, ' ').replace(regAmp, '&');
            }
            break;
          case "picker":
          case "pickergroup":
          case "radio":
            if (typeof data[key] === "object" && data[key] !== null && data[key].hasOwnProperty('value')) {
              if (field.changeValues) {
                if (data[key].value === -1 ){
                  data[key] = null;
                } else if (data[key].value === 0) {
                  data[key] = false;
                } else if (data[key].value === 1) {
                  data[key] = true;
                }
              } else {
                if (data[key].value === -1){
                  data[key] = null;
                } else {
                  data[key] = data[key].value;
                }
              }
            } else if(field.changeValues && typeof data[key] === "number" ) {
                if (data[key] === -1 ){
                  data[key] = null;
                } else if (data[key] === 0) {
                  data[key] = false;
                } else if (data[key] === 1) {
                  data[key] = true;
                }
            } else if (field.multi) {
              if (Array.isArray(data[key])) {
                data[key] = data[key].map(d => d.value ? d.value : d)
              }
            }
            break;
          case "checkbox":
            if (Array.isArray(data[key]) && data[key].length > 0) {
              const dataArray = [];
              let dataInt = 0;
              if (field.flags) {
                data[key].forEach(d => {
                  dataInt += d.value;
                });
                data[key] = dataInt;
              } else {
                data[key].forEach(d => {
                  if (d.hasOwnProperty("value")) {
                    dataArray.push(d.value);
                  } else {
                    dataArray.push(d);
                  }
                });
                data[key] = dataArray;
              }
            }
            break;
          case "checkbox_custom":
            if (Array.isArray(data[key])) {
              data[key] = data[key].filter(d => d.favorite).map(d => (d.uuid || d.id));
            }
            break;
          case "numeric":
            if (data[key] !== null && typeof data[key] === "string") {
              data[key] = parseFloat((data[key]).replace(',','.'))
            }
            break;
          case "currency":
            if (data[key] !== null) {
              data[key] = numbro.unformat(data[key]);
            }
            break;
          // case "images":
          case "dokumenti":
            if (field.object) {
              data[key] = data[key][0];
            }
          break;
          case "table":
            const arr = data[key];
            const parseArr = [];
            arr.forEach(rec => {
              // TODO:
              // check why is here prepareInsertData
              parseArr.push(this.prepareInsertData(rec));
            })
            data[key] = parseArr;
            break;
        }
        // } else {
        //   delete data[key];
        // }
      } else {
        delete data[key];
        console.warn("preparing data. cannot find field ", key, record);
      }
    });
    if (this.fieldId) {
      data[this.fieldId.source] = record[this.fieldId.source]; //ensure we have recordId
    }

    return data;
  }

  getField(name) {
    const field = this.fields.find(f => f.source === name);
    return field ? JSON.parse(JSON.stringify(field)) : null; //clone
  }

  getFields(fieldNames) {
    const fields = fieldNames
      ? fieldNames.map(name => this.fields.find(f => f.source === name))
      : [];
    return JSON.parse(JSON.stringify(fields)); //clone
  }

  //Helpers

  overrideFieldConfigurations(baseFields, finalFields) {
    finalFields.forEach(f => {
      if (typeof f === "object") {
        Object.keys(f).forEach(key => {
          if (key !== "source") {
            let baseField = baseFields.find(x => x.source == f.source);
            if (baseField.hasOwnProperty(key)) {
              if (f[key] !== null) {
                baseField[key] = f[key];
              } else {
                delete baseField[key];
              }
            } else {
              if (f[key] !== null) {
                baseField[key] = f[key];
              }
            }
          }
        });
      }
    });
    return baseFields;
  }

  //View

  getView(viewId) {
    if (viewId !== undefined && this.listViews.hasOwnProperty(viewId)) {
      return this.listViews[viewId];
    } else if (this.listViews.hasOwnProperty("default")) {
      return this.listViews["default"];
    } else {
      return this.errorObj(this.listViews, "default");
    }
  }

  getViewFieldsNames(viewId) {
    const view = this.getView(viewId);
    return view && view.fields && Array.isArray(view.fields) ? view.fields.map(x => (typeof x === "object" ? x.source : x)) : this.errorObj(view, "fields", "array");
  }

  getViewFields(viewId) {
    const view = this.getView(viewId);
    if (view === null) {
      return this.errorObj(view, "", "array");
    }

    const fieldNames = this.getViewFieldsNames(viewId);
    let baseFields = this.getFields(fieldNames);

    baseFields = this.overrideFieldConfigurations(baseFields, view.fields);

    return baseFields;
  }

  getHiddenViewFieldsNames(viewId) {
    const view = this.getView(viewId);
    if (view !== null && view.hasOwnProperty("hidden")) {
      return ["id"].concat(view.hidden);
    } else {
      return ["id"];
    }
  }

  //Form

  getForm(formId) {
    if (this.forms === undefined) {
      return null;
    } else if (formId !== undefined && this.forms[formId]) {
      return this.forms[formId];
    } else if (this.forms.default) {
      return this.forms.default;
    } else {
      return this.errorObj(this.forms, "default");
    }
  }

  getFormFieldsNames(formId) {
    const form = this.getForm(formId);
    return form && form.fields && Array.isArray(form.fields) ? form.fields.map(x => (typeof x === "object" ? x.source : x)) : this.errorObj(form, "fields", "array");
  }

  getFormFields(formId) {
    const form = this.getForm(formId);
    if (form === null) {
      return this.errorObj(form, "", "array");
    }
    const fieldNames = this.getFormFieldsNames(formId);
    let baseFields = this.getFields(fieldNames);

    baseFields = this.overrideFieldConfigurations(baseFields, form.fields);

    return baseFields;
  }

  //Validator

  getValidator(formId) {
    const form = this.getForm(formId);
    return form && form.validator ? form.validator : this.errorObj(form, "validator");
  }

  //Error handling

  /**
    * @description Error logger for objects
    * @name errorObj
    * @params 
    *   obj: object
    *   key: string (optional)
    *   type: string (optional)
    * @returnVal
    *   if type == "array" return value will be an empty array else the function returns null
    */
  errorObj(obj, key = "", type = null) {
    if (process.env.REACT_APP_DEVELOPMENT) {
      return type === "array" ? [] : null;      
    }

    if (obj === null || obj === undefined) {
      console.error("Object", obj, "is null or undefined!");
    } else if (!obj.hasOwnProperty(key)) {
      console.error("Object", obj, "doesn't contain the key:", key);
    } else if (obj[key] === null || obj[key] === undefined) {
      console.error("Object", obj, "value under key:", key, "is", obj[key]);
    } else if (type !== null) {
      if (type === "array" && !Array.isArray( obj[key] )) {
        console.error("Object", obj, "value under key:", key, "is not an Array!");
      } else if (typeof obj[key] !== type) {
        console.error("Object", obj, "value under key:", key, "type:", typeof obj[key] ," is not expected type:", type, "!");
      }
    } else {
      console.error("Unknown error!", obj, key, type);
    }

    return type === "array" ? [] : null;
  } 
}

export default dataController;
