import api from "Lib/api";

const valpovoApiUrl = process.env.VALPOVO_APIPATH;
const nasiceApiUrl = process.env.NASICE_APIPATH;

export const stablaService = {
  getStabla
}

function getStabla(path){
  const apiInstance = new api();

  return apiInstance.Call("vrste-stabala/", "get", {}, path).then((resp) => {
    if(resp.success){
      return resp.data;
    } else {
      return Promise.reject(undefined)
    }
  })
}
