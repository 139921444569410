import api from "Lib/api";

const valpovoApiUrl = process.env.VALPOVO_APIPATH;
const nasiceApiUrl = process.env.NASICE_APIPATH;

export const qrService = {
  getQRCodeById,
  getQRCodePhoto
}

function getQRCodeById(id, path) {
  const apiInstance = new api();

  return apiInstance.Call("qr/" + id, "get", {}, path).then((resp)=> {
    console.log(resp)
    if(resp.data !== null){
      return resp.data;
    } else {
      return Promise.reject("notifications.no_qr")
    }
  })
}

function getQRCodePhoto(id, path) {
  const apiInstance = new api();

  return apiInstance.Call("qr/photo/" + id, "get", {}, path).then((resp)=> {
    console.log(resp)
    if(resp.data !== null){
      return resp.data;
    } else {
      return Promise.reject("notifications.no_qr")
    }
  })
}
