import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//Material-UI Core Components
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";

//Material-UI Icons
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

//Custom Components
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import ToolbarFillContent from "UI/Toolbar/ToolbarFillContent";
import HeaderDropdown from "UI/Header/HeaderDropdown";
import HeaderButton from "UI/Header/HeaderButton";
import HeaderMenuButton from "UI/Header/HeaderMenuButton";
import HeaderMenuItem from "UI/Header/HeaderMenuItem";
import LanguageSelector from "UI/Header/LanguageSelector";
import InfoButton from "UI/Header/InfoButton";

const drawerWidth = 240;

const makeAppBarStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
    justifyContent: "center"
  },
  link: {
    textDecoration: "none",
    paddingLeft: 10,
    paddingRight: 10
  },
  titleLink: {
    color: "#fff",
    textDecoration: "none",
    fontFamily: "'Work Sans', sans-serif"
  },
  activeLink: {
    color: "#FFD33A",
    textDecoration: "underline"
  }
}));

const makeToolbarStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      }
    }
  },
}));

function AppHeader(props) {
  const appBarClasses = makeAppBarStyles();
  const toolbarClasses = makeToolbarStyles();
  const { t } = useTranslation();
  const history = useHistory();


  return (
    <AppBar position="fixed" color="primary" className={`${appBarClasses.appBar}`} >
      <Toolbar className={toolbarClasses.root} variant="dense" disableGutters={true}>
        <GridContainer justify="space-evenly" alignItems="center">
          <GridItem xs={2}>
            {/*<InfoButton/>*/}
          </GridItem>
          <GridItem xs={8}>
            <Typography
              variant="h6"
              component="h2"
              className={appBarClasses.grow}
              
              style={{ color: "#fff", fontSize: "1.15rem" }}
            >
              <NavLink to="/" className={appBarClasses.titleLink}>
                {t("app_title")}
              </NavLink>
            </Typography>
          </GridItem>
          {/* <ToolbarFillContent>
            <Typography variant="h6" component="h2" className={appBarClasses.grow} style={{ color: "#fff", fontSize: "1.15rem" }}>
              <NavLink to="/" className={appBarClasses.titleLink}>
                {t("app_title")}
              </NavLink>
            </Typography>
          </ToolbarFillContent> */}
          <GridContainer item  xs={2} sm={2} justify="flex-end" alignContent="flex-end">
            <LanguageSelector />
          </GridContainer>

        </GridContainer>
      </Toolbar>
    </AppBar>
  );
}

export default AppHeader;
