import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

//Custom components
import AppPage from "UI/AppPage/AppPage";
import { stablaService } from "Services/stablaService";
import GridContainer from "UI/Grid/GridContainer";
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import GridItem from "UI/Grid/GridItem";
import StablaCard from "Views/StablaPage/StablaCard";
import StablaSearchForm from "Views/StablaPage/StablaSearchForm";
import model from "Models/stabla";
import dataController from "Lib/dataController";
import fuzzySearch from "Lib/fuzzySearch";

//Material UI core
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  stablaList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: "10px",
    [theme.breakpoints.down('md')]:{
      marginBottom: "80px"
    },
    [theme.breakpoints.up('md')]:{
      marginBottom: "170px"
    }
  }
}))

function StablaPage(props) {

  const {t} = useTranslation();
  const snackbarContext = useContext(SnackbarContext);
  const classes = useStyles();

  const dc = new dataController(model);

  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchFilters, setSearchFilters] = useState(null);
  const {match} = props;

  const refreshData = () => {
    const url = match.params.url;
    setIsLoading(true);
    setRecords([]);
    stablaService.getStabla(url).then((res) => {
      if (res) {
        setRecords(res);
      } else {
        snackbarContext.showNotification("There are no records", "error");
      }
    })
        .finally(() => {
          setIsLoading(false);
        });
  };


  useEffect(() => {
    // match.params.url
    refreshData();
  }, [])

  const handleRefresh = (evt) => {
    refreshData();
  };

  const handleSearch = (filters) => {
    setSearchFilters({
      searchFilters: filters
    })
  }

  const filterResults = () => {

    let resultRecords = records.slice();

    if (searchFilters) {
      Object.keys(searchFilters.searchFilters).forEach(key => {
        const fuzzy = new fuzzySearch([key], records);
        const filterValue = searchFilters.searchFilters[key];

        if (filterValue === null || filterValue === "" || (Array.isArray(filterValue) && filterValue.length === 0)) {
          //skip
        } else {
          switch (key) {
            case 'naziv_lat':
            case 'naziv_en':
            case 'naziv_hr':
            case 'naziv_hu':
            case 'naziv_de':
              resultRecords = resultRecords.filter(r => {
                if (r.hasOwnProperty(key) && r[key] !== null) {
                  return r[key].toLowerCase().indexOf(filterValue.toLowerCase()) >= 0
                } else {
                  return false;
                }
              });
              break;
          }
        }
      })
    }

    return resultRecords;
  }

  const filteredRecords = filterResults(records);

  return (
      <AppPage>
        {/*<StablaSearchForm dc={dc} form="search" onSearchRequest={handleSearch}/>*/}
        <GridContainer className={classes.stablaList}>
          {filteredRecords.map((record, i) => {
            return (
                <GridItem key={i}>
                  <StablaCard record={record}/>
                </GridItem>
            )
          })}
        </GridContainer>
        <Backdrop
            open={isLoading}
            style={{
              zIndex: 99999,
              color: "#fff"
            }}
        >
          <CircularProgress disableShrink/>
        </Backdrop>
      </AppPage>
  );
}

export default StablaPage;
