import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

//Custom components
import AppPage from "UI/AppPage/AppPage";
import Splitter from 'm-react-splitters';
import 'm-react-splitters/lib/splitters.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

//Material UI Core
import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import makeStyles from "@material-ui/core/styles/makeStyles";

const paragraphs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#fff", marginTop: "50px", cursor: "pointer",
    backgroundColor: "rgba(14, 34, 7, 0.7)",
    margin: '5px',
    borderRadius: "10px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "2.6rem"
    },
    [theme.breakpoints.up('md')]: {
      fontSize: "4rem"
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "6rem"
    },
    textAlign: "center"
  },
  accordion: {
    textAlign: "center", padding: "5px", marginTop: "10px",
    backgroundColor: "rgba(14, 34, 7, 0.7)",
    borderRadius: "10px",
    [theme.breakpoints.down('sm')]: {
      width: "95%"
    },
    [theme.breakpoints.up('sm')]: {
      width: "80%"
    },
    marginBottom: "110px"
  },
  bottomMargin: {
    marginBottom : "110px",
    [theme.breakpoints.down('xs')]: {
      marginBottom: "45px"
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: "180px"
    }
  },
  flex: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overscrollX: 'hidden',
    height: '100%'
  },
  iconRoot: {
    width: '100px',
    height: '100px'
  },
  button: {
    margin: '10%'
  }
}));

function HomePage() {

  const [openedLeft, setOpenedLeft] = useState(false);
  const [openedRight, setOpenedRight] = useState(false);
  const [primaryPaneSize, setPrimaryPaneSize] = useState(50);
  const [expandedRight, setExpandedRight] = useState(false);
  const [expandedLeft, setExpandedLeft] = useState(false);

  const {t} = useTranslation();
  const classes = useStyles();

  const handleNasice = () => {
    setOpenedLeft(true);
    setOpenedRight(false);
    setExpandedLeft(true);
    setPrimaryPaneSize(100);
  }

  const handleValpovo = () => {
    setOpenedLeft(false);
    setOpenedRight(true);
    setExpandedRight(true);
    setPrimaryPaneSize(0);
  }

  const handleResetPanes = () => {
    setOpenedLeft(false);
    setExpandedLeft(false);
    setExpandedRight(false);
    setOpenedRight(false);
    setPrimaryPaneSize(50);
  };

  const toGeoPortalNasice = () => {
    window.open(process.env.REACT_APP_NASICE_WEB_GIS_PATH);
  }

  const toGeoPortalValpovo = () => {
    window.open(process.env.REACT_APP_VALPOVO_WEB_GIS_PATH)
  }

  return (
      <AppPage>
        <Splitter
            split="vertical"
            primaryPaneMinWidth="0%"
            primaryPaneWidth={primaryPaneSize + '%'}
            primaryPaneMaxWidth="100%"
            primaryPaneClassName="pane1"
            secondaryPaneClassName="pane2"
            style={{marginBottom:'110px'}}
        >
          <Fade in={primaryPaneSize > 25} timeout={500}>
            <div className={classes.flex} onClick={openedLeft ? null : () => handleNasice()}>
              {primaryPaneSize === 100 ? (
                  <div style={{position: "fixed", display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%", zIndex: "10"}}>
                    <Fade in={openedLeft} timeout={3000}>
                      <IconButton onClick={handleResetPanes} color="secondary"><FontAwesomeIcon size="2x" icon={faArrowAltCircleLeft} className={classes.icon}/></IconButton>
                    </Fade>
                  </div>
              ) : null}
              <Typography
                  variant="h1"
                  className={classes.title}
                  color="primary"
              >
                {!openedLeft ? t("titles.nasice") : t("titles.nasice_open")}
              </Typography>
              {openedLeft ? (
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "center", width: "100%"}}>
                    <Fade in={openedLeft} timeout={800}>
                      <Button color="primary" variant="contained" onClick={toGeoPortalNasice} style={{marginRight: "5px"}}>
                        {t("buttons.geo_port")}
                      </Button>
                    </Fade>
                  </div>
              ) : null}
              {!openedLeft ?
                  <Fade in={!openedLeft} timeout={800}>
                    <Card className={classes.accordion}>
                      <Typography variant="subtitle1" paragraph style={{color: "white"}}>
                        {t("nasice:header")}
                      </Typography>
                    </Card>
                  </Fade> : null
              }
              {expandedLeft ?
                  <Fade in={openedLeft} timeout={800}>
                    <Accordion
                        className={classes.accordion}
                        classes={{expanded: classes.bottomMargin}}
                        expanded={expandedLeft}
                    >
                      <AccordionDetails style={{display: "inline"}}>
                        <Typography variant="subtitle1" paragraph style={{color: "white"}}>
                          {t("nasice:header")}
                        </Typography>
                        {paragraphs.map((paragraph, index) => (
                            <Typography variant="subtitle1" key={index} paragraph style={{color: "white"}}>
                              {t("nasice:p" + paragraph)}
                            </Typography>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Fade> : null
              }
              <Fade in={false}>
                  <Button classes={{root: classes.button}}></Button>
              </Fade>
            </div>
          </Fade>
          <Fade in={primaryPaneSize < 75} timeout={500}>
            <div className={classes.flex} onClick={openedRight ? null : () => handleValpovo()}>
              {primaryPaneSize === 0 ? (
                  <div style={{position: "fixed", display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", zIndex: "10"}}>
                    <Fade in={openedRight} timeout={3000}>
                      <IconButton onClick={handleResetPanes} color="secondary"><FontAwesomeIcon size="2x" icon={faArrowAltCircleRight} className={classes.icon}/></IconButton>
                    </Fade>
                  </div>
              ) : null}
              <Typography
                  variant="h1"
                  className={classes.title}
              >
                {!openedRight ? t("titles.valpovo") : t("titles.valpovo_open")}
              </Typography>
              {openedRight ? (
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "center", width: "100%"}}>
                    <Fade in={openedRight} timeout={800}>
                      <Button color="primary" variant="contained" onClick={toGeoPortalValpovo} style={{marginRight: "5px"}}>
                        {t("buttons.geo_port")}
                      </Button>
                    </Fade>
                  </div>
              ) : null}
              {!openedRight ?
                  <Fade in={!openedRight} timeout={800}>
                    <Card className={classes.accordion}>
                      <Typography variant="subtitle1" paragraph style={{color: "white"}}>
                        {t("valpovo:header")}
                      </Typography>
                    </Card>
                  </Fade> : null
              }
              {expandedRight ?
                  <Fade in={openedRight} timeout={800}>
                    <Accordion
                        classes={{root: classes.accordion, expanded: classes.bottomMargin}}
                        expanded={expandedRight}
                    >
                      <AccordionDetails style={{display: "inline"}}>
                        <Typography variant="subtitle1" paragraph style={{color: "white"}}>
                          {t("valpovo:header")}
                        </Typography>
                        {paragraphs.map((paragraph, index) => (
                            <Typography variant="subtitle1" key={index} paragraph style={{color: "white"}}>
                              {t("valpovo:p" + paragraph) !== "p" + paragraph ? t("valpovo:p" + paragraph) : null}
                            </Typography>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Fade> : null
              }
              <Fade in={false}>
                <Button classes={{root: classes.button}}></Button>
              </Fade>
            </div>
          </Fade>
        </Splitter>
      </AppPage>
  );
}

export default HomePage;
