import React, { Fragment, useContext, useEffect, useState } from 'react';

//Material UI Core
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

//Custom components
import AppPage from "UI/AppPage/AppPage";
import GridContainer from "UI/Grid/GridContainer";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HtmlParser from "Components/HTMLParser";
import { qrService } from "Services/qrService";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import SnackbarContext from "UI/SnackbarContext/SnackbarContext";
import DialogContext from "UI/DialogContext/DialogContext";
import ImageDialog from "./ImageDialog";

const cardStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "70px",
    marginTop: "20px",
    borderRadius: "5px",
    padding: "20px",
    [theme.breakpoints.up('md')]:{
      width: "600px",
      marginBottom: "180px"
    },
  },
  image:{
    width: "300px",
    height: "400px",
    borderRadius: "5px",
    [theme.breakpoints.down('sm')]:{
      width: "180px",
      height: "300px"
    }
  }
}))

function QrPage(props) {

  const { t } = useTranslation();
  const { match } = props;//used for react-router params to get id from path
  const classes = cardStyles();
  const snackBarContext = useContext(SnackbarContext);
  const dialogContext = useContext(DialogContext);

  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [record, setRecord] = useState(null);

  useEffect(() => {
    const id = match.params.id;
    const path = match.path;
    if (path === undefined || path === null || path.length === 0) {
      console.error("No path specified!");
      return;
    }

    qrService.getQRCodeById(id, path).then((res)=> {
      const images = [];
      images.push({path_thumb: `data:image/jpeg;base64,${res.photo_1_thumb}`, pid : res.photo_1_public_id})
      images.push({path_thumb: `data:image/jpeg;base64,${res.photo_2_thumb}`, pid : res.photo_2_public_id})
      images.push({path_thumb: `data:image/jpeg;base64,${res.photo_3_thumb}`, pid : res.photo_3_public_id})
      setRecord(res)
      setImages(images);
    }).catch((err)=> {
      snackBarContext.showNotification(err, "error");
    })
  }, []);

  const handleOpenImageDialog = (pid) => {
    const path = match.params.path;
    dialogContext.showDialog(ImageDialog, {
      pid: pid,
      title: generateTitle(i18next.language),
      path: path,
      onClose: handleDialogClose
    })
  }

  const handleDialogClose = () => {
    console.log("here")
    dialogContext.hideDialog();
  }


  const handleMoveSliderLeft = () => {
    if (index === 0) {
      setIndex(images.length - 1)
    } else {
      setIndex(index - 1)
    }
  }

  const handleMoveSliderRight = () => {
    if (index + 2 > images.length) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }

  const upperCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const generateTitle = (lng) => {
    if(record) {
      switch (lng) {
        case "en":
          return upperCase(record.naziv_en)
          break;
        case "hr":
          return upperCase(record.naziv_hr)
          break;
        case "de":
          return upperCase(record.naziv_de)
          break;
        case "hu":
          return upperCase(record.naziv_hu)
          break;
      }
    }
  }

  const generateDescription = (lng) => {
    if(record) {
      switch (lng) {
        case "en":
          return (
              <HtmlParser wrapper="light" htmlString={record.opis_html_en}/>
          )
          break;
        case "hr":
          return (
              <HtmlParser wrapper="light" htmlString={record.opis_html_hr}/>
          )
          break;
        case "de":
          return (
              <HtmlParser wrapper="light" htmlString={record.opis_html_de}/>
          )
          break;
        case "hu":
          return (
              <HtmlParser wrapper="light" htmlString={record.opis_html_hu}/>
          )
          break;
      }
    }
  }

  const hasImages = Array.isArray(images) && images.length > 0;

  return (
      <AppPage>
        {record !== null ? (
            <Card className={classes.root}>
              <Typography variant="h5" style={{textAlign:"center"}}>
                {record ? (<HtmlParser wrapper="light" htmlString={record.naziv_lat_html}/> ): null}
              </Typography>
              <Typography variant="h6" style={{textAlign:"center"}}>
                {generateTitle(i18next.language)}
              </Typography>
              <GridContainer style={{
                display: "flex",
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginBottom: '10px',
                marginTop: '10px'
              }}>
                <IconButton
                    value="left"
                    onClick={() => handleMoveSliderLeft()}
                    data-toggle="tooltip"
                    title="Left"
                >
                  <FontAwesomeIcon icon={faChevronLeft}/>
                </IconButton>
                {hasImages ?
                <img src={images[index].path_thumb} className={classes.image} onClick={() => handleOpenImageDialog(images[index].pid)}/> : null }
                <IconButton
                    value="right"
                    onClick={() => handleMoveSliderRight()}
                    data-toggle="tooltip"
                    title="Right"
                >
                  <FontAwesomeIcon icon={faChevronRight}/>
                </IconButton>
              </GridContainer>
              {generateDescription(i18next.language)}
            </Card>
        ):null}
      </AppPage>
  );
}

export default QrPage;
