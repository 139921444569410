import React from 'react';
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

//Material UI Core
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';

//Custom components
import HtmlParser from "Components/HTMLParser";
import i18next from "i18next";



const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function StablaCard(props) {

  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { record } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const generateDescription = (lng) => {
    if(record) {
      switch (lng) {
        case "en":
          return (
              <HtmlParser wrapper="light" htmlString={record.opis_html_en}/>
          )
          break;
        case "hr":
          return (
              <HtmlParser wrapper="light" htmlString={record.opis_html_hr}/>
          )
          break;
        case "de":
          return (
              <HtmlParser wrapper="light" htmlString={record.opis_html_de}/>
          )
          break;
        case "hu":
          return (
              <HtmlParser wrapper="light" htmlString={record.opis_html_hu}/>
          )
          break;
      }
    }
  }

  const generatePhotoLabel = (lng) => {
    if(record) {
      switch (lng) {
        case "en":
          return record.photo_1_label_en;
          break;
        case "hr":
          return record.photo_1_label_hr;
          break;
        case "de":
          return record.photo_1_label_de;
          break;
        case "hu":
          return record.photo_1_label_hu;
          break;
      }
    }
  }

  const generateName = (lng) => {
    if(record) {
      switch (lng) {
        case "en":
          return record.naziv_en ? record.naziv_en.charAt(0).toUpperCase() + record.naziv_en.slice(1) : '';
          break;
        case "hr":
          return record.naziv_hr ? record.naziv_hr.charAt(0).toUpperCase() + record.naziv_hr.slice(1) : '';
          break;
        case "de":
          return record.naziv_de ? record.naziv_de.charAt(0).toUpperCase() + record.naziv_de.slice(1) : '';
          break;
        case "hu":
          return record.naziv_hu ? record.naziv_hu.charAt(0).toUpperCase() + record.naziv_hu.slice(1) : '';
          break;
      }
    }
  }

  const naziv = record && record.naziv_hr ? record.naziv_hr.charAt(0).toUpperCase() + record.naziv_hr.slice(1) : '';

  return (
      <Card className={classes.root}>
        <CardHeader
            title={<HtmlParser wrapper="light" htmlString={record.naziv_lat_html}/>}
            subheader={generateName(i18next.language)}
        />
        <CardMedia
            className={classes.media}
            image="https://picsum.photos/id/1039/300/300"
        />
        <Typography style={{textAlign:"center"}}>
          {generatePhotoLabel(i18next.language)}
        </Typography>
        <CardActions disableSpacing>
          <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              {generateDescription(i18next.language)}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
  );
}

export default StablaCard;
