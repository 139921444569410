import React, { Fragment } from 'react'

import withStyles from "@material-ui/core/styles/withStyles";

//Custom Components
import AppHeader from 'UI/Header/AppHeader';
import Footer from 'UI/Footer/Footer';

import menuRoutes from "Routes/menu";

const style = (theme) => ({
  content: {
    flexGrow: 1,
  },
});

class AppPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {classes} = this.props; //HOC withStyles

    return (
        <div id="page">
          <AppHeader menu={menuRoutes}/>
          <main id="content-wrapper" className={classes.content}>
              {this.props.children}
          </main>
          <Footer/>
        </div>
    );
  }
}

export default withStyles(style)(AppPage);
