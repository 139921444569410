import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";

//Custom Components
import DraggableDialog from "UI/Dialog/DraggableDialog";
import DialogHeader from "UI/Dialog/DialogHeader";
import DialogAvatar from "UI/Dialog/DialogAvatar";
import DialogToolbarHeading from "UI/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "UI/Dialog/DialogToolbarButtonClose";
import DialogToolbarFillContent from "UI/Dialog/DialogToolbarFillContent";
import DialogBody from "UI/Dialog/DialogBody";
import DialogContext from "UI/DialogContext/DialogContext";
import Loader from "UI/Loader/Loader";
import { qrService } from "Services/qrService";
import withStyles from "@material-ui/core/styles/withStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";

const imageStyles = {
  width: "100%",
  height: "100%",
  borderRadius: "5px"
};

const useClasses = makeStyles((theme) => ({
  dialog:{
    width: "100%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  paper:{
    margin: "0px"
  },
  title: {
    color: "#FFFFFF"
  }
}))

function ImageDialog(props) {
  const { t } = useTranslation();

  const dialogContext = useContext(DialogContext);

  const { pid, path, onClose, title } = props;
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const classes = useClasses();

  useEffect(() => {
      qrService.getQRCodePhoto(pid, path).then((photo) => {
        setSelectedPhoto(`data:image/jpeg;base64,` + photo.base64);
      }).catch((err) => console.error(err));
  }, [])

  const closeDialog = () => {
    console.log('something')
    dialogContext.hideDialog();
  }


  return (
      <DraggableDialog open={true} fullWidth={true} onClose={onClose} classes={{root: classes.dialog, paper: classes.paper}}>
        <DialogHeader>
          <Toolbar variant="dense" disableGutters={true}>
            <DialogAvatar ariaLabel="image" icon={<i className="fas fa-image"></i>} />
            <DialogToolbarHeading>{title}</DialogToolbarHeading>
            <DialogToolbarFillContent />
            <DialogToolbarButtonClose onClick={closeDialog} />
          </Toolbar>
        </DialogHeader>
        <DialogBody style={{overflowX: "hidden", padding: "0px"}}>
          {selectedPhoto ? (
              <img src={selectedPhoto} style={imageStyles}/>
          ): null}
        </DialogBody>
      </DraggableDialog>
  );
}

export default ImageDialog;
