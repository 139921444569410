// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/našice_final.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../images/valpovo_final.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "html,\nbody {\n    height: 100%;\n    width: 100%;\n    padding: 0;\n    margin: 0;\n    overflow-x: hidden;\n    overflow-y: hidden;\n}\n\n#root {\n    width: 100%;\n}\n\n#page {\n    height: 100vh; /* for shorter content */\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    overflow-y: auto;\n\n    /*ie10*/\n    display: -ms-flexbox;\n    -ms-flex-direction: column;\n}\n\n#content-wrapper {\n    flex: 1;\n    padding-top: 48px;\n\n    /*ie10*/\n    -ms-flex: 1 1 auto;\n    overflow-y: auto;\n    overflow-x: hidden;\n}\n\n#header {\n    padding: 10px;\n}\n\n#header,\n#footer{\n    display: flex;\n    flex: 1;\n    flex-basis: 48px;\n\n    /*ie10*/\n    -ms-flex-preferred-size: 50px;\n}\n\n#footer {\n    display: flex;\n    flex: 1;\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n    margin-top: auto;\n    flex-shrink: 0;\n    /* height: 100px; */\n    /* Height of the footer */\n}\n\n.swal2-container {\n    z-index: 1460 !important;\n}\n\nbody.swal2-height-auto{\n    height: 100% !important;\n}\n\n.splitter {\n    position: relative;\n    display: flex;\n    flex: 0 0 100%;\n    align-content: flex-start;\n    user-select: text;\n    \n}\n.splitter .pane:first-child {\n    overflow-y: auto;\n    transition: width 1.0s;\n}\n\n.pane1{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position-x: 50%;\n    background-position-y: 50%;\n    transition: width 1.0s;\n    transition-timing-function: cubic-bezier(.46,.16,.18,1);\n}\n\n.pane2{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");    \n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position-x: 50%;\n    background-position-y: 50%;\n}\n\n.splitter .pane:last-child {\n    overflow-y: auto;\n    transition: width 1.0s;\n}\n\n.handle-bar.vertical {\n  display: none;\n}", ""]);
// Exports
module.exports = exports;
