import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
    popOverRoot: {
        [theme.breakpoints.up('md')]: {
            width: '40%'
        },
    },
    cardRoot: {
        padding: '5px'        
    }
}));

function InfoButton(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const version = process.env.REACT_APP_APPVERSION;

  const handleInfo = function (evt) {
    setAnchorEl(evt.currentTarget);
    setOpen(true);
  };

  const closeInfo = function (evt) {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <Fragment>
      <IconButton onClick={handleInfo}>
        <InfoIcon />
      </IconButton>
      <Popover
        onClose={closeInfo}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        classes={{root: classes.popOverRoot}}
      >
        <Card classes={{root: classes.cardRoot}}>
          <Typography variant="caption">
            {t("footer.copyright")}, {currentYear}.
          </Typography>
          <Typography variant="caption">
            {t("footer.author")}, {t("footer.app_version")}: {version}
          </Typography>
          <Typography variant="caption">{t("footer.financed")}</Typography>
          <Typography variant="caption">{t("footer.project")}</Typography>
          <Typography variant="caption">
            {t("footer.responsibility")}
          </Typography>
        </Card>
      </Popover>
    </Fragment>
  );
}

export default InfoButton;
