import React from 'react'
import { withTranslation } from 'react-i18next';

import EULenta from "Images/lenta.png";
import EULentaOld from "Images/EUlentaold.png";

//Material-UI Core Components
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

//Custom Components
import GridContainer from 'UI/Grid/GridContainer';
import GridItem from 'UI/Grid/GridItem';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ( {
  toolbar: {
    height: 95,
    display: "flex",
    justifyContent: "center",
    ...theme.mixins.toolbar,
    minHeight: "48px !important",
    cursor: "pointer"
  },
  gridContainer: {
    height: "80%"
  },
  center: {
    color: "#000",
    textAlign: "center",
    justifyContent: "center",
    height: "100%"
  },
  icons:{
    height: "100%",
    verticalAlign: "middle"
  },
  image:{
    width: "100%",
    cursor: "pointer",
    verticalAlign: "middle"
  }
}))

//TODO: put EU logos for footer

function Footer(props) {

  const classes = useStyles();

  const toHTML = () => {
    window.open("/docs/EU_fond_page.html")
  }

  return (
      <footer id="footer">
          <GridContainer spacing={0} className={classes.gridContainer} justify="center">
            <GridItem xs={12} sm={12} md={8} lg={8}>
              <Typography color="primary" className={classes.center}>
                <img src={EULentaOld} className={classes.image} onClick={toHTML}/>
              </Typography>
            </GridItem>
          </GridContainer>
      </footer>
  );
}

export default Footer;
