export function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        window.location.reload(true);
      }

      if (response.status === 404) {
        return Promise.reject({message: response.statusText, errorCode: response.status});
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject({ message: error, errorCode: data.errorCode});
    }

    return data;
  });
}
