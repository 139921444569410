import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import publicRoutes from "Routes/public";

import ThemeProvider from "Components/ThemeContext/ThemeProvider";
import SnackbarProvider from "UI/SnackbarContext/SnackbarProvider";
import DialogProvider from "UI/DialogContext/DialogProvider";
import ScrollToTop from "Components/ScrollToTop";
import DialogContainer from "UI/DialogContext/DialogContainer";
import SnackbarContainer from "UI/SnackbarContext/SnackbarContainer";
import PublicRoute from "Components/PublicRoute";
import { createBrowserHistory } from "history";

var history = createBrowserHistory();

function App(props) {
  return (
      <ThemeProvider>
          <SnackbarProvider>
              <DialogProvider>
                <BrowserRouter history={history} basename={process.env.REACT_APP_DEPLOYFOLDER}>
                  <ScrollToTop>
                    <Switch>
                      {publicRoutes.map((prop, key) => (
                          <PublicRoute path={prop.path} key={key} component={prop.component} />
                      ))}
                    </Switch>
                  </ScrollToTop>
                  <DialogContainer />
                </BrowserRouter>
              </DialogProvider>
            <SnackbarContainer />
          </SnackbarProvider>
      </ThemeProvider>
  );
}

export default App;
