import React, { Fragment } from 'react'
// import moment from 'moment';
import { withTranslation } from 'react-i18next';
import withSnackbar from "Components/withSnackbar";

import appValidator from 'Lib/appValidator';
import dataControllerSubModel from "Lib/dataControllerSubModel";

import radio from "Models/submodels/radio";
import checkbox from "Models/submodels/checkbox";
import picker from "Models/submodels/picker";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

function withFormController(WrappedComponent) {

  return class extends React.Component {
    constructor(props) {
      super(props);
      const { t } = props;

      this.handleFieldChange = this.handleFieldChange.bind(this);
      this.handleClose = this.handleClose.bind(this);

      this.handleInsert = this.handleInsert.bind(this);
      this.handleUpdate = this.handleUpdate.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
      this.handleClear = this.handleClear.bind(this);
      this.handleSearch = this.handleSearch.bind(this);
      this.handleRefresh = this.handleRefresh.bind(this);
      this.handleValidate = this.handleValidate.bind(this);

      this.serverErrorPromise = this.serverErrorPromise.bind(this);
      this.getFields = this.getFields.bind(this);
      this.getDefaultValues = this.getDefaultValues.bind(this);
      this.getFieldsWithModel = this.getFieldsWithModel.bind(this);
      this.checkIfChanged = this.checkIfChanged.bind(this);
      this.validate = this.validate.bind(this);
      this.validateField = this.validateField.bind(this);

      this.validator = new appValidator(t);

      let record = {};

      if (props.recordId) {
        // do nothing -> we will get data on mount
      } else if (props.record) {
        record = props.record;
      } else {
        if (props.mode === "insert") {
          record = this.getDefaultValues();
        }
      }

      const recordCopy = Object.assign({}, record);
      const originalRecord = Object.assign({}, record);

      this.state = {
        recordId: props.recordId,
        originalRecord: originalRecord,
        record: recordCopy,
        validation: null,
        validated: false,
        dataChanged: false,
        subModels: null,
        loader: false
      }
    }

    componentDidMount() {
      const { recordId } = this.state;
      const { defaultValues, dc, mode } = this.props;
      this.mounted = true;

      if (recordId) {
        this.setState({
          loader: true
        })
        dc.GetDataSingle(recordId)
        .then(resp => {
          if (resp.success) {
            const record = resp.data;

            if (defaultValues && mode === "insert") {
              Object.keys(defaultValues).forEach(key => {
                if (!record.hasOwnProperty(key) || record[key] === undefined || record[key] === null) {
                  record[key] = defaultValues[key];
                }
              })
            }

            const recordCopy = Object.assign({}, record);
            const originalRecord = Object.assign({}, record);

            this.setState({
              originalRecord: originalRecord,
              record: recordCopy
            });

          }
        }).finally(() => {
          if (this.mounted) {
            this.setState({
              loader: false})
          }
        });
      }

      const fieldsWithModel = this.getFieldsWithModel();

      Promise.all(fieldsWithModel).then(fModels => {
        const subModels = {};
        fModels.forEach(f => {
          const modelType = f.type === 'picker' ? picker
            : f.type === 'radio' ? radio
            : f.type === 'checkbox' ? checkbox
            : null;
          if (modelType !== null) {
            Object.assign(subModels, {[f.source]: new dataControllerSubModel(modelType, f)})
          }
        })
        this.setState({
          subModels: subModels
        })
      })
      .catch(err => {
        console.error("Error catching submodels", err);
      })

      // Promise.all(fieldSchemaNames.map(name => require(`Models/submodels/${name}`)))
      //   .then(models => {
      //     const subModels = {};
      //     models.forEach(model => {
      //       Object.assign(subModels, {[model.source]: new dataControllerSubModel(model)})
      //     })
      //     this.setState({
      //       subModels: subModels
      //     })
      //   })
      //   .catch(err => {
      //     console.error("Error catching submodels", err);
      //   })
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    getDefaultValues(){
      const { dc, t, defaultValues } = this.props;
      const record = {};
      // console.log(defaultValues);
      //set default values from fields
      dc.fields.forEach(attr => {
        if( attr.items && attr.items.hasOwnProperty('default') ){
          if ( attr.type === 'boolean' ){
            record[ attr.source ] = attr.items.default;
          } else if ( attr.type === 'radio' ){
            const pos = attr.items.values.indexOf( attr.items.default );
            record[ attr.source ] = {
              label: t( attr.items.labels[ pos ] ),
              value: attr.items.default
            }
          }
        }
      })

      //set default values from props
      if (defaultValues !== undefined && defaultValues !== null) {
        Object.keys(defaultValues).forEach(key => {
          record[key] = defaultValues[key];
        })
      }

      return record;
    }

    getFieldsWithModel() {
      const fields = this.getFields();

      const fieldModels = fields.filter(f => f.subModel);
      return fieldModels;
    }

    handleRefresh(){
      const { recordId } = this.state;
      const { dc } = this.props;

      if (recordId) {

        dc.GetDataSingle(recordId)
        .then(resp => {
          if (resp.success) {
            const record = resp.data

            const recordCopy = Object.assign({}, record);
            const originalRecord = Object.assign({}, record);

            this.setState({
              originalRecord: originalRecord,
              record: recordCopy
            });
          }
        });
      }
    }

    handleFieldChange(value, source) {
      const { validated } = this.state;

      this.setState(prevState => {
        let record = prevState.record
        if (!record) {
          record = {};
        }
        record[source] = value;
        return {
          record: record,
          dataChanged: this.checkIfChanged(record)
        }
      }, () => {
        if (validated) { this.validateField(source); }
      });
    }

    handleInsert() {
      const { record } = this.state;
      const { dc } = this.props;
      const { t } = this.props; //HOC withTranslation

      const isValid = this.validate();

      if (isValid) {
        this.setState({loader: true})
        return dc.InsertRecord(record)
          .then((response) => {
            if (response && response.success) {
              const newId = response.data.id;
              this.setState({
                recordId: newId
              });
              if (this.props.onDataUpdate) {
                this.props.onDataUpdate();
              }
              return Promise.resolve({ success: true, id: newId });
            } else {
              return Promise.reject();
            }
          })
          .catch((response) => {
            switch (response.error.errorCode) {
              case 401:
                return this.serverErrorPromise({
                  email: {
                    valid: false,
                    msg: t("errors.user_email_exists")
                  }
                });
              }
            return Promise.resolve({
              success: false,
              validationPass: true,
              error: t("errors.saving") + " " + ( response && response.error && response.error.message ? response.error.message : '')
            })
          })
          .finally(() => {
            if (this.mounted) {
              this.setState({loader: false})
            }
          })
      } else {
        return Promise.resolve({ success: false, validationPass: false })
      }
    }

    handleUpdate() {
      const { record, recordId, validation } = this.state;
      const { dc } = this.props;
      const { t } = this.props; //HOC withTranslation

      const isValid = this.validate();

      if (isValid) {
        this.setState({loader: true});
        return dc.UpdateRecord(record.id, record)
          .then((response) => {
            if (response && response.success) {
              this.setState({
                dataChanged: false,
                validated: false
              })

              if (this.props.onDataUpdate) {
                this.props.onDataUpdate();
              }

              return Promise.resolve({ success: true, updated: true });
            } else {
              return Promise.resolve({ success: false });
            }
          })
          .catch((x) => {
            return Promise.resolve({ success: false, validationPass: true, error: t("errors.saving") })
          })
          .finally(() => {
            if (this.mounted) {
              this.setState({loader: false})
            }
          })
      } else {
        return Promise.resolve({ success: false, validationPass: false, validation: validation });
      }
    }

    handleDelete() {
      const { recordId } = this.state;
      const { t, dc } = this.props;

      return this.confirmDelete()
        .then(result => {
          if (result.canceled) {
            return Promise.resolve({ success: false, canceled: true });
          }
          if (result.confirmed) {
            return dc.DeleteRecord(recordId)
              .then(response => {
                if (response && response.success) {
                  if (this.props.onDataUpdate) {
                    this.props.onDataUpdate();
                  }
                  return Promise.resolve({ success: true });
                } else {
                  if (response.error) {
                    return Promise.resolve({ success: false, error: response.error.errorCode.toString() })
                  } else {
                    return Promise.resolve({ success: false })
                  }
                }
              })
              .catch(response => {
                return Promise.resolve({ success: false, error: t("errors.deleting")});
              })

          }
          else {
            return Promise.resolve({ success: false });
          }
        })
    }

    handleClear() {
      this.setState({
        record: {}
      })
      return Promise.resolve({ success: true });
    }

    handleSearch() {
      const isValid = this.validate();
      const { record } = this.state;

      if (isValid) {
        return Promise.resolve({ success: true, filters: record })
      }
      else {
        return Promise.resolve({ success: false, validationPass: false });
      }
    }

    handleClose(externalDataChanged = false) {
      const { dataChanged } = this.state;

      if (dataChanged || externalDataChanged) {
        return this.confirmClose()
          .then(result => {
            if (result.canceled) {
              return Promise.resolve({ success: false, canceled: true });
            }
            else if (result.confirmed) {
              return Promise.resolve({ success: false, shouldsave: true });
              //return this.handleUpdate();
            } else {
              return Promise.resolve({ success: true });
            }
          })
      } else {
        return Promise.resolve({ success: true })
      }
    }

    handleValidate() {
      return this.validate();
    }

    serverErrorPromise(serverValidation) {
      this.setState({
        validation: serverValidation
      });
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation: serverValidation
      });
    }

    checkIfChanged(record) {
      const { originalRecord } = this.state;
      const changed = this.validator.checkIfRecordChanged(record, originalRecord);
      return changed;
    }

    getValidator() {
      const { dc, form, mode } = this.props;

      const formId = form ? form : mode;
      const validateFunc = dc.getValidator(formId);
      if (this.validator[validateFunc] !== undefined && typeof this.validator[validateFunc] === 'function') {
        return this.validator[validateFunc];
      } else {
        return () => ({});
      }
    }

    getFields() {
      const { fieldNames, dc, form, controllerForm, mode } = this.props;
      //console.log(fieldNames, dc, form, controllerForm, mode)
      let formId = form ? form : mode;

      let fields = [];

      //get all fields for controller
      if (controllerForm) {
        fields = dc.getFormFields(controllerForm);
      }

      //override with custom definitions
      let formFields = dc.getFormFields(formId);
      if (formFields && formFields.length) {
        return formFields
      }
      formFields.forEach(f => {
        const ind = fields.findIndex(x => x.source == f.source);
        if (ind >= 0) {
          fields[ind] = f;
        } else {
          fields.push(f);
        }
      })

      //filter by fieldnames if necessary
      if (fieldNames) {
        return fields.filter(f => fieldNames.indexOf(f.source) >= 0);
      } else {
        return fields;
      }
    }

    validate() {
      const { record } = this.state;
      const { t } = this.props;
      const { showNotification } = this.props;
      const fields = this.getFields();

      //model validation
      let validation = this.validator.validateModel(record, fields);
      console.log(validation)
      //custom validation

      const customValidation = this.getValidator()(record, t);
      const finalValidation = this.validator.mergeValidation(validation, customValidation);
      const isValid = this.validator.checkIfValid(finalValidation);
      //console.log(finalValidation)

      this.setState({
        validation: finalValidation,
        validated: true
      });

      if (!isValid) {
        showNotification("messages.required_fields", "warning");
      }

      return isValid;
    }

    validateField(source) {
      const { record } = this.state;
      const fields = this.getFields();
      const field = fields.find(f => f.source === source);

      let fieldValidation = { valid: true }

      if (field) {
        fieldValidation = this.validator.validateField(record, field);
      }

      this.setState(prevState => {
        const { validation } = prevState;
        validation[source] = fieldValidation;
        return { validation: validation };
      });


    }

    render() {
      const { record, validation, validated, subModels, dataChanged, loader } = this.state;

      const fields = this.getFields();

      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            validation={validation}
            validated={validated}
            subModels={subModels}
            dataChanged={dataChanged}
            fields={fields}
            record={record}

            onFieldChange={this.handleFieldChange}
            doClose={this.handleClose}
            doActivate={this.confirmActivate}
            doInsert={this.handleInsert}
            doUpdate={this.handleUpdate}
            doDelete={this.handleDelete}
            doClear={this.handleClear}
            doPrepareSearch={this.handleSearch}
            doRefresh={this.handleRefresh}
            doValidate={this.handleValidate}
          />
          <Backdrop
            open={loader}
            style={{
              zIndex: 99999,
              color: '#fff'
            }}
          >
            <CircularProgress/>
          </Backdrop>
        </Fragment>
      )
    }
  }
}

export default (Component) => withSnackbar(withTranslation()(withFormController(Component)));
