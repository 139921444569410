import React from 'react';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

//Material-UI Core Components
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { fade, makeStyles } from "@material-ui/core/styles";

//Material-UI Icons
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

//Custom Components
import withFormController from "Components/withFormController";
import withSnackbar from 'Components/withSnackbar';
import GridContainer from "UI/Grid/GridContainer";
import GridItem from "UI/Grid/GridItem";
import FormContent from "Components/FormContent";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  card: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "345px",
    height: "205px",
    marginTop: "10px"
  },
  searchBars: {
    display: "flex"
  },
  button: {
    margin: 5
  }
}))


function StablaSearchForm(props) {

  const {t} = useTranslation();
  const {record, validation, fields, subModels} = props;
  const classes = useStyles();

  const handleFieldChange = (value, source) => {
    const {onFieldChange} = props; //HOC withFormController

    if (onFieldChange) {
      onFieldChange(value, source);
    }
  }

  const handleSearch = (evt) => {
    const {doPrepareSearch} = props; //HOC withFormController
    const {showNotification} = props; //HOC withSnackbar
    const {onSearchRequest, t} = props;

    if (doPrepareSearch) {
      doPrepareSearch()
          .then(result => {
            if (result.success) {
              if (onSearchRequest) {
                onSearchRequest(result.filters);
              }
              showNotification(t('notifications.action'), "success");
            }
          })
    }
  }

  const handleClearFilter = (evt) => {
    const {doClear} = props; //HOC withFormController
    const {onSearchRequest} = props;

    if (doClear) {
      doClear().then(result => {
        if (result.success) {
          if (onSearchRequest) {
            onSearchRequest({});
          }
        }
      })
    }
  }

  const chosenLanguageSearch = () => {
    switch (i18next.language) {
      case "en":
        return ['naziv_lat', 'naziv_en']
        break;
      case "hu":
        return ['naziv_lat', 'naziv_hu']
        break;
      case "de":
        return ['naziv_lat', 'naziv_de']
        break;
      case "hr":
        return ['naziv_lat', 'naziv_hr']
        break;
    }
  }


  return (
      <Card className={classes.card}>
        <CardContent>
          <GridContainer spacing={0} direction="row" alignItems="center" justify="space-evenly">
              <Button color="primary" variant="contained" onClick={handleSearch} className={classes.button}>
                {t("buttons.search")}
              </Button>
              <Button color="primary" variant="outlined" onClick={handleClearFilter}>
                Clear
              </Button>
              <FormContent
                  record={record}
                  validation={validation}
                  fields={fields}
                  fieldNames={chosenLanguageSearch()}
                  onFieldChange={handleFieldChange}
                  onSubmit={(evt) => handleSearch(evt)}
                  subModels={subModels}
                  columns={3}
              />
          </GridContainer>
        </CardContent>
      </Card>
  );
}

export default withFormController(withSnackbar(StablaSearchForm));
